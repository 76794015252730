import { graphql } from "gatsby"
import get from "lodash/get"
import * as React from "react"
import { ProductCard } from "../components/ProductCard/ProductCard"
import Layout from "../layouts/RootLayout"

class ShopIndex extends React.Component {
  render() {
    const articles = get(this, "props.data.vendure.search.items")
    const collections = get(this, "props.data.vendure.collections.items")
    return (
      <Layout>
        <div className="bg-secondary">
          <div className="bg-dark pt-4 pb-5">
            <div className="container pt-2 pb-3 pt-lg-3 pb-lg-4">
              <div className="d-lg-flex justify-content-between pb-3">
                <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                      <li className="breadcrumb-item">
                        <a className="text-nowrap" href="/">
                          <i className="czi-home"></i>Accueil
                        </a>
                      </li>
                      <li className="breadcrumb-item text-nowrap">
                        <a href="#">Boutique</a>
                      </li>
                      <li
                        className="breadcrumb-item text-nowrap active"
                        aria-current="page"
                      >
                        Tous les articles
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
                  <h1 className="h3 text-light mb-0">Boutique</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-5 mb-2 mb-md-4">
            <br></br>
            <div className="row no-gutters mx-n2">
              {articles.map(item => (
                <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 px-2 mb-3">
                  <ProductCard
                    collections={collections}
                    product={item}
                    key={item.productId}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default ShopIndex

export const pageQuery = graphql`
  query ShopQuery {
    site {
      siteMetadata {
        title
      }
    }
    vendure {
      collections {
        items {
          id
          name
        }
      }
      search(input: { take: 100, groupByProduct: true }) {
        items {
          productId
          productName
          description
          slug
          currencyCode
          collectionIds
          priceWithTax {
            ... on Vendure_PriceRange {
              min
              max
            }
            ... on Vendure_SinglePrice {
              value
            }
          }
          productAsset {
            preview
            imageFile {
              childImageSharp {
                fluid(
                  maxWidth: 600
                  maxHeight: 600
                  fit: CONTAIN
                  background: "rgba(255,255,255,1)"
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
